import { PostsResponse, Post } from "../types/Post";
async function getPosts() {
  const res = await fetch("https://journey.tawnyzhao.workers.dev/posts");
  const data = await (res.json() as Promise<PostsResponse>);
  return data.map((post) => ({ ...post, date: new Date(post.date) }));
}

async function createPost(
  post: Pick<Post, "username" | "location" | "content" | "image" | "date">
) {
  const response = await fetch("https://journey.tawnyzhao.workers.dev/posts", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(post), // body data type must match "Content-Type" header
  });
  return response.body; // parses JSON response into native JavaScript objects
}

export { getPosts, createPost };
