import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TiCamera, TiHeart, TiLocation } from "react-icons/ti";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { like } from "../api/like";
import { createPost, getPosts } from "../api/posts";
import { Post, PostsResponse } from "../types/Post";

function HomeLayout({ children }: { children: React.ReactNode }) {
  return (
    <Box bg="gray.200" minH="100vh" p={8}>
      <Center>
        <Container maxW="container.lg">{children}</Container>;
      </Center>
    </Box>
  );
}

function Submission() {
  const queryClient = useQueryClient();
  const mutation = useMutation<
    any,
    any,
    Pick<Post, "username" | "location" | "content" | "image" | "date">
  >(createPost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
    },
  });
  const [location, setLocation] = useState("");
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("Anon");
  const [image, setImage] = useState("");

  return (
    <Stack spacing={4} boxShadow="base" bg="white" m={2} p={6} rounded="md">
      <Heading size="md">What did you do today?</Heading>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={TiLocation} color="gray.400" />}
        />
        <Input
          size="md"
          placeholder="I visited..."
          value={location}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLocation(e.target.value)
          }
        />
      </InputGroup>
      <Textarea
        placeholder="and here's what I did..."
        value={content}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setContent(e.target.value)
        }
      />
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={TiCamera} color="gray.400" />}
        />
        <Input
          placeholder="with a picture - url (optional)"
          value={image}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setImage(e.target.value)
          }
        />
      </InputGroup>
      <Flex alignItems="center">
        <Text color="gray.500"> Posting as Anonymous </Text>
        <Spacer />
        <Button
          colorScheme="teal"
          isLoading={mutation.isLoading}
          onClick={() => {
            mutation.mutate({
              username,
              location,
              content,
              image,
              date: new Date(),
            });
            setLocation("");
            setContent("");
            setImage("");
          }}
        >
          Post my journey
        </Button>
      </Flex>
      {mutation.isError && mutation.error.message ? (
        <Box>An error occurred: {mutation.error.message}</Box>
      ) : null}
    </Stack>
  );
}

function Entry({ post }: { post: Post }) {
  const queryClient = useQueryClient();

  const { username, location, date, content, image, likes, id } = post;

  const mutation = useMutation<any, any, string>(like, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
    },
  });
  const [liked, setLiked] = useState(false);
  return (
    <Stack spacing={2}>
      <Box>
        <Heading size="md">{`${username} visited ${location}`}</Heading>
        <Text color="teal.400">{date.toLocaleString()}</Text>
      </Box>
      <Text>{content}</Text>
      {image ? (
        <Image borderRadius="lg" boxSize="sm" fit="cover" src={image}></Image>
      ) : null}
      <Flex>
        <Box>
          <Icon as={TiHeart} color="red.500" />
          <Text color="red.500"> {likes} likes </Text>
        </Box>
        <Spacer />
        <Button
          colorScheme="teal"
          isDisabled={liked}
          onClick={() => {
            mutation.mutate(id);
            setLiked(true);
          }}
        >
          {liked ? "Liked" : "Like"}
        </Button>
      </Flex>
    </Stack>
  );
}
function Home() {
  const { isLoading, isError, data, error } = useQuery<PostsResponse, any>(
    "posts",
    getPosts
  );

  return (
    <HomeLayout>
      <Stack spacing={8}>
        <Center>
          <Heading
            fontFamily={`"Pacifico"`}
            size="2xl"
            textShadow="3px 3px white"
            color="teal.400"
          >
            Journey
          </Heading>
        </Center>
        <Submission />

        <Stack spacing={4} bg="white" boxShadow="base" p={6} rounded="md">
          {isLoading ? <Spinner /> : null}
          {isError ? <span>Error: {error.message}</span> : null}
          {data
            ? data.map((post, i) => (
                <React.Fragment key={post.id}>
                  {i > 0 ? <Divider /> : null}
                  <Entry post={post} />
                </React.Fragment>
              ))
            : null}
        </Stack>
      </Stack>
    </HomeLayout>
  );
}

export { Home };
